@use 'sass:meta';
@use '../state-styles';

@import '/home/jenkins/agent/workspace/PREPROD/mia-webapp/src/theme-config.scss';

@import '../config';
@import '../functions';
@import '../mixins';
@import './action-menu-option-config';

@include state-styles.configure('ace-c-action-menu-option', $action-menu-option-config);

.ace-c-action-menu-option {
    @include typography(
        $typography-styles,
        map-get($action-menu-option-config, action-menu-option-typography)
    );

    position: relative;
    min-width: map-get($action-menu-option-config, action-menu-option-min-width);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: map-get($action-menu-option-config, action-menu-option-padding);
    color: map-get($action-menu-option-config, action-menu-option-text-color);
    background: map-get($action-menu-option-config, action-menu-option-background);
    border-bottom: map-get($action-menu-option-config, action-menu-option-border);
    cursor: pointer;
    user-select: none;

    &--small {
        height: 40px;
        padding:
            map-get($action-menu-option-config, action-menu-option-padding-small)
            map-get($action-menu-option-config, action-menu-option-padding);
    }

    @include state-styles.block() using($get-design-token) {
        @include typography($typography-styles, meta.call($get-design-token, action-menu-option-typography));

        color: meta.call($get-design-token, action-menu-option-text-color);
        background: meta.call($get-design-token, action-menu-option-background);
        border-bottom: meta.call($get-design-token, action-menu-option-border);
    }
}
