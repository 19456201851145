@import '/home/jenkins/agent/workspace/PREPROD/mia-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './notification-bar-config';

.ace-c-notification-bar {
    min-height: map-get($notification-bar-config, notification-bar-height);
    background-color: map-get($notification-bar-config, notification-bar-background);
    z-index: map-get($notification-bar-config, notification-bar-z-index);

    &--pending {
        background: map-get($notification-bar-config, notification-bar-background-pending);
    }

    &--positive {
        background: map-get($notification-bar-config, notification-bar-background-positive);
    }

    &--negative {
        background: map-get($notification-bar-config, notification-bar-background-negative);
    }

    &--information {
        background: map-get($notification-bar-config, notification-bar-background-information);
    }

    &--primary {
        background: map-get($notification-bar-config, notification-bar-background-primary);
    }
}

.ace-c-notification-bar__content {
    max-width: map-get($notification-bar-config, content-max-width);
    min-width: map-get($notification-bar-config, content-min-width);
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;

    @include media('>=x-large') {
        margin: 0 auto;
    }
}
