@import '/home/jenkins/agent/workspace/PREPROD/mia-webapp/src/theme-config.scss';
@import '../config';

$sticky-bar-config: (
    sticky-bar-background-color-small: map-get($base-colors, background-b),
    sticky-bar-background-color-large: map-get($base-colors, background-main),
);

.ace-c-sticky-bar {
    position: sticky;
    background: map-get($sticky-bar-config, sticky-bar-background-color-small);
    padding: 32px 16px;

    &--bottom {
        bottom: 0;
    }

    @include media('<=small') {
        position: fixed;
        box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.1);
        z-index: 100;

        &--margin-small-16 {
            width: calc(100% + 32px);
            margin: 32px -16px 0;
            padding-right: 32px;
        }

        &--margin-small-32 {
            width: calc(100% + 64px);
            margin: 32px -32px 0;
            padding: 32px 72px 32px 8px;
        }
    }

    @include media('>=small') {
        position: relative;
        background: map-get($sticky-bar-config, sticky-bar-background-color-large);
    }
}
