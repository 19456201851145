@import '/home/jenkins/agent/workspace/PREPROD/mia-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './input-with-button-config';

.ace-c-input-with-button {
    display: flex;
    flex-direction: column;

    p {
        margin-top: map-get($sizes, ace-8);
    }

    margin-bottom: map-get($sizes, ace-8);
}

.ace-c-input-with-button__content {
    display: flex;
    flex-direction: row;

    &--focused {
        box-shadow: map-get($input-with-button-config, input-shadow-color);
    }
}

.ace-c-input-with-button__input {
    width: 100%;
    margin-bottom: 0;
}

.ace-c-input-with-button__button {
    min-width: 56px;
    width: 56px;
    padding: 0;

    &:focus {
        background: map-get($input-with-button-config, button-primary-background);
        box-shadow: unset;
        outline: unset;
    }
}

.ace-c-input-with-button__content--error {
    box-shadow: 0 0 0 4px map-get($input-with-button-config, box-shadow-error);

    .ace-c-input-with-button__button {
        background: map-get($input-with-button-config, button-color-error);

        &:focus {
            background-color: map-get($input-with-button-config, button-color-hover-error);
        }

        &:active:not(.ace-c-button-primary--is-disabled) {
            background: map-get($input-with-button-config, button-color-active);
        }

        &:hover:not(.ace-c-button-primary--is-disabled):not(:active) {
            background: map-get($input-with-button-config, button-color-hover-error);
        }
    }

    &:focus-within {
        box-shadow: 0 0 0 4px map-get($input-with-button-config, box-shadow-error);
    }
}
