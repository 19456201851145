@import '/home/jenkins/agent/workspace/PREPROD/mia-webapp/src/theme-config.scss';
.ace-c-lock-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5f5f6;
    opacity: 0.3;
    cursor: not-allowed;
}
