@import '/home/jenkins/agent/workspace/PREPROD/mia-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './header-bar-config';

.ace-c-header-bar {
    height: map-get($header-bar-config, header-bar-height);
    background-color: map-get($header-bar-config, header-bar-background);
    z-index: map-get($header-bar-config, header-bar-z-index);

    &--primary-navigation {
        background-color: map-get($header-bar-config, header-bar-background-primary);
        box-shadow: map-get($header-bar-config, header-bar-shadow-primary);
    }

    &--is-sticky {
        position: sticky;
        top: 0;
        z-index: map-get($header-bar-config, header-bar-z-index-sticky);
    }
}

.ace-c-header-bar__content {
    max-width: map-get($header-bar-config, content-max-width);
    min-width: map-get($header-bar-config, content-min-width);
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;

    @include media('>=x-large') {
        margin: 0 auto;
    }
}
