@import '/home/jenkins/agent/workspace/PREPROD/mia-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './text-area-config';

.ace-c-text-area {
    @include typography($typography-styles, map-get($text-area-config, text-area-typography));

    display: inline;
    height: map-get($text-area-config, text-area-height);
    padding: map-get($text-area-config, text-area-spacing-small);
    border: 1px solid map-get($text-area-config, text-area-border-color);
    caret-color: map-get($text-area-config, text-area-caret-color);
    background: map-get($text-area-config, text-area-background-color);

    &--small {
        height: map-get($text-area-config, text-area-height-small);
    }

    &--medium {
        height: map-get($text-area-config, text-area-height-medium);
    }

    &::-webkit-input-placeholder {
        color: map-get($text-area-config, text-area-placeholder-text);
    }

    &:focus {
        outline: none;
        box-shadow: map-get($text-area-config, text-area-box-shadow-focus);
        border: map-get($text-area-config, text-area-border-focus);
    }

    &--is-disabled {
        background: map-get($text-area-config, text-area-background-color-disabled);
        border: 1px solid map-get($text-area-config, text-area-border-color-disabled);
        color: map-get($text-area-config, text-area-text-disabled);
    }

    &--has-error {
        border: 1px solid map-get($text-area-config, text-area-border-color-error);
        color: map-get($text-area-config, text-area-text-error);
    }

    &--not-resizable {
        resize: none;
    }

    &--is-required:not(#{&}--is-disabled) {
        border: 1px solid map-get($text-area-config, text-area-border-color-empty-mandatory);
    }
}
