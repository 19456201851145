@import '/home/jenkins/agent/workspace/PREPROD/mia-webapp/src/theme-config.scss';
@import '../config';

.ace-c-icon {
    display: inline-block;
    width: map-get($icon-config, icon-size-24);
    height: map-get($icon-config, icon-size-24);
    user-select: none;

    &--has-on-click {
        cursor: pointer;
    }

    &--no-margin {
        margin: 0;
    }

    &--16 {
        width: map-get($icon-config, icon-size-16) !important;
        height: map-get($icon-config, icon-size-16) !important;
    }

    &--24 {
        width: map-get($icon-config, icon-size-24) !important;
        height: map-get($icon-config, icon-size-24) !important;
    }

    &--32 {
        width: map-get($icon-config, icon-size-32) !important;
        height: map-get($icon-config, icon-size-32) !important;
    }

    &--48 {
        width: map-get($icon-config, icon-size-48) !important;
        height: map-get($icon-config, icon-size-48) !important;
    }

    &--64 {
        width: map-get($icon-config, icon-size-64) !important;
        height: map-get($icon-config, icon-size-64) !important;
    }
}

.ace-c-icon__symbol {
    width: 100%;
    height: 100%;
    display: inherit;
    fill: map-get($icon-config, icon-symbol-default);

    @each $icon-color in $icon-color-states {
        .ace-c-icon--color-#{$icon-color} & {
            fill: map-get($icon-config, icon-symbol-#{$icon-color});
        }
    }
}
