@import '/home/jenkins/agent/workspace/PREPROD/mia-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './file-input-config';

.ace-c-file-input {
    border: map-get($file-input-config, file-input-border);

    &--no-chrome {
        border: none;
    }
}

.ace-c-file-input__native-input {
    opacity: 0;
    position: absolute;
    left: -999px;
}
