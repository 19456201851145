@import '/home/jenkins/agent/workspace/PREPROD/mia-webapp/src/theme-config.scss';
@import '../config';
@import '../mixins';
@import './input-currency-config';

.ace-c-input-currency {
    @include typography($typography-styles, map-get($input-currency-config, input-currency-text-typography));

    display: inline-flex;
    position: relative;
    padding: 0;
    background-color: inherit;
    justify-content: flex-end;
}

.ace-c-input-currency__input {
    padding-right: map-get($input-currency-config, input-currency-input-padding-right);

    .ace-c-input-currency--small & {
        height: 40px;
    }

    &--has-error {
        color: map-get($input-currency-config, input-currency-input-color-error);
        border: 1px solid map-get($input-currency-config, input-currency-input-border-color-error);
        box-shadow: map-get($input-currency-config, input-currency-input-box-shadow-error);
    }
}

.ace-c-input-currency__currency-sign {
    position: absolute;
    top: 16px;
    right: 16px;

    .ace-c-input-currency--small & {
        top: 8px;
    }

    &--is-disabled {
        color: map-get($input-currency-config, input-currency-currency-sign-disabled);
    }

    &--has-error {
        color: map-get($input-currency-config, input-currency-currency-sign-error);
    }
}
